<template>
    <div class="page-content">
        <page-breadcrumb v-if="item" title="Level Chest Detail" class="mb-2" :items="breadcrumbItems" />
        <section class="mb-2">
        <b-card class="mt-1" v-if="item">
            <form-generator :model="item" :schema="item_form_schema">
                <template #slot_boosters>
                    <append-select 
                    :items="item.boosters" 
                    :options="booster_options" 
                    :title="'Booster'" 
                    :default_data="{booster_id: null, quantity: null}" 
                    :id_field="'booster_id'"
                    />
                </template>
                <template #slot_support_items>
                    <append-select 
                    :items="item.support_items" 
                    :options="support_item_options" 
                    :title="'Support Item'" 
                    :default_data="{support_item_id: null, quantity: null}" 
                    :id_field="'support_item_id'"
                    />
                </template>
            </form-generator>
        </b-card>
        </section>
        <action-buttons
            update-button
            back-button
            delete-button
            @update="update"
            :is-updating="isUpdating"
            @delete="deleteItem"
            :is-deleting="isDeleting"
        />
    </div>  
    
</template>
<script>
const item_form_schema = [
    {
        cols: 12,
        fields: [
            { label: 'Level', field: 'level_number', validate: { required: true } },
            { label: 'Coin', field: 'coin'},
            { label: 'Unlimited Lives (in minutes)', field: 'unlimited_lives' },
            { label: 'Booster', field: 'boosters', input_type: 'slot'},
            { label: 'Support Items', field: 'support_items', input_type: 'slot'},
        ]
    }
];
import service from '../service';
import supportItemService from '../../support_item/service';
import boosterService from '../../booster/service';
export default {
    data(){
        return{
            item_form_schema,
            item: null,
            isUpdating: false,
            isDeleting: false,
            booster_options: [],
            support_item_options: []
        }
    },
    computed: {
        itemId() {
            return this.$route.params.id;
        },
        breadcrumbItems() {
            let items = [
                {
                    text: 'Level Chests',
                    to: { name: 'level-chest-list' },
                },
                {
                    text: `${this.item ? this.item.title : this.itemId}`, active: true
                },
            ]
            return items
        },
        authLanguage() {
            return this.$store.getters["auth/language"];
        },
    },
    created(){
        this.getDetail();
        // this.getBooster();
        this.getUsableItem();
    },
    methods:{
        async getDetail(){
            this.item = await service.get({id: this.itemId});
        },
        async update() {
            this.isUpdating = true;
            let data = {...this.item};
            await service.update({
                data: JSON.stringify(data),
            });
            this.getDetail();
            this.isUpdating = false;
        },
        async deleteItem() {
            await service.delete({ id: this.itemId });
            this.$router.push({ name: "level-chest-list" });
        },
        async getUsableItem(){
			let data = await supportItemService.getAll();
			if (data){
				if (this.support_item_options.length){
					this.support_item_options = [];
				}
				if (this.booster_options.length){
					this.booster_options = [];
				}
				let options = data.filter(item => item.category  === 'support_item').map((item)=> {
					return {
						text: item.title || item.name,
						value: item._id,
					}
				})
				this.support_item_options.push(...options)
				options = data.filter(item => item.category  === 'booster').map((item)=> {
					return {
						text: item.title || item.name,
						value: item._id,
					}
				})
				this.booster_options.push(...options)
			}
		},
        // async getBooster(){
		// 	let data = await boosterService.getAll();
		// 	if (data){
		// 		if (this.booster_options.length){
		// 			this.booster_options = [];
		// 		}
		// 		let options = data.map((item)=> {
		// 			return {
		// 				text: item.name,
		// 				value: item._id,
		// 			}
		// 		})
		// 		this.booster_options.push(...options)
		// 	}
		// },
    }
}
</script>